module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","de"],"defaultLanguage":"de","siteUrl":"https://www.ezagrar.at","trailingSlash":"always","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/news/:slug","excludeLanguages":["en"]},{"matchPath":"/:lang?/news/:slug","getLanguageFromPath":true,"languages":["en"]},{"matchPath":"/hersteller/:slug","excludeLanguages":["en"]},{"matchPath":"/:lang?/manufacturer/:slug","getLanguageFromPath":true,"excludeLanguages":["de"]}]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"EZ Agrar","short_name":"ezagrar","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"src/assets/favicons/mstile-310x310.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7b4d712a534f1ded56872ef1ef752f77"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
